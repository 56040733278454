import { type PropsWithChildren } from 'react';

import { Cluster, Stack, Text } from '@ssnc/ui-core';

import { type ProductGroupProps } from './types';

export function ProductGroup({ children, title }: PropsWithChildren<ProductGroupProps>) {
  return (
    <Stack gap={150}>
      <Text type="h5">{title}</Text>
      <Cluster gap={200}>{children}</Cluster>
    </Stack>
  );
}
