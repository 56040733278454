import { useIntl } from 'react-intl';

import { Box, Button, Cluster } from '@ssnc/ui-core';
import { CardHeader, Card } from '@ssnc/ui-pattern-card';

import { type ProductCardProps } from './types';

export function ProductCard({ label, buttonLabel, onClick }: ProductCardProps) {
  const { formatMessage } = useIntl();

  function handleClick() {
    onClick && onClick();
  }

  return (
    <Box width="30rem">
      <Card>
        <CardHeader title={label} />
        <Cluster justify="end" padding={200}>
          <Button importance="primary" onClick={handleClick}>
            {formatMessage({ id: buttonLabel })}
          </Button>
        </Cluster>
      </Card>
    </Box>
  );
}
