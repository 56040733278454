import React from 'react';

import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
} from 'react-router-dom';

import { ThemeType, ThemeProvider } from '@ssnc/ui-core';
import { ToastContainer } from '@ssnc/ui-pattern-toasts';

import { LanguageProvider } from '@blueprism/nextgen-localisation';
import { commonVocabulary } from '@blueprism/nextgen-platform-components';
import { tableVocabulary } from '@blueprism/nextgen-platform-table';
import { ApiErrorBoundary, apiVocabulary } from '@blueprism/nextgen-api';

import { AccountPageLayout, AccountDetails, Onboarding, Products } from 'pages/Account';
import { AccountCreation, ProductConfiguration, TenantList } from 'pages';
import { ProductActivationFormDrawer } from 'components';
import { Layout } from 'layout';
import { ConfigProvider } from 'services/config';
import { AuthProvider } from 'services/auth';
import { ApiProvider } from 'services/api';
import { RegionContextProvider } from 'services/region';
import { AppRoutes } from 'routes';
import { vocabulary } from 'translations';

export function AppProviders() {
  return (
    <ConfigProvider>
      <ThemeProvider theme={ThemeType.LIGHT}>
        <ToastContainer locale="en-US">
          <LanguageProvider locale="en" messages={[commonVocabulary, tableVocabulary, apiVocabulary, vocabulary]}>
            <AuthProvider>
              {({ allowRender }) =>
                allowRender && (
                  <ApiProvider>
                    <RegionContextProvider>
                      <Layout>
                        <Outlet />
                      </Layout>
                    </RegionContextProvider>
                  </ApiProvider>
                )
              }
            </AuthProvider>
          </LanguageProvider>
        </ToastContainer>
      </ThemeProvider>
    </ConfigProvider>
  );
}

export function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppProviders />}>
        <Route path={AppRoutes.AccountAdd} element={<AccountCreation />} />
        <Route
          path={AppRoutes.ProductConfiguration}
          element={
            <ApiErrorBoundary>
              <ProductConfiguration />
            </ApiErrorBoundary>
          }
        />
        <Route path={AppRoutes.Account} element={<TenantList />} />
        <Route path={AppRoutes.Account} element={<AccountPageLayout />}>
          <Route path={AppRoutes.AccountDetail} element={<AccountDetails />} />
          <Route
            path={AppRoutes.Products}
            element={
              <ApiErrorBoundary>
                <ProductActivationFormDrawer>
                  <Products />
                </ProductActivationFormDrawer>
              </ApiErrorBoundary>
            }
          />
          <Route path={AppRoutes.Onboarding} element={<Onboarding />} />
        </Route>
        <Route path="*" element={<Navigate to={AppRoutes.Account} />} />
      </Route>,
    ),
    { basename: process.env.PUBLIC_URL },
  );

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}
