import { useIntl } from 'react-intl';

import { useTheme, Stack, Text, Button } from '@ssnc/ui-core';
import { Screen, Refresh } from '@ssnc/ui-icons';

import Logo from 'assets/logo.svg';

import { ErrorMessage } from './ServiceDownErrorPage.styled';

export function ServiceDownErrorPage() {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  function handleRefresh() {
    window.location.reload();
  }

  return (
    <Stack height="100vh">
      <Stack padding={1200} justify="center" align="center">
        <img src={Logo} alt="Logo" />
      </Stack>
      <Stack justify="center" align="center">
        <Stack width="35rem" gap={100}>
          <Stack align="center">
            {/* @ts-ignore COMPLIB-2110 The theme is not available from the outside of complib */}
            <Screen size={54} color={theme.sys.color.icon.action} />
          </Stack>
          <Stack align="center">
            <Text type="h5">{formatMessage({ id: 'errorPage.title' })}</Text>
          </Stack>
          <ErrorMessage align="center">
            <Text>{formatMessage({ id: 'errorPage.content' })}</Text>
          </ErrorMessage>
          <Stack align="center">
            <Button icon={<Refresh />} onClick={handleRefresh}>
              {formatMessage({ id: 'buttons.refresh' })}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
