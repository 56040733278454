import { useIntl } from 'react-intl';

import { Row, Box } from '@ssnc/ui-core';
import { FormSection } from '@ssnc/ui-pattern-forms';

import { ScrollToTop } from '@blueprism/nextgen-platform-components';

import { AccountAdminDetailsForm, TenantDetailsForm, AccountDetailsControlGroup } from './components';

export function AccountDetails() {
  const { formatMessage } = useIntl();

  return (
    <Row width="100%" justify="between">
      <ScrollToTop />
      <Box width="40rem">
        <FormSection title={formatMessage({ id: 'account.details.btn.accountDetails' })}>
          <TenantDetailsForm />
        </FormSection>
        <FormSection title={formatMessage({ id: 'account.details.title.administratorDetails' })}>
          <AccountAdminDetailsForm />
        </FormSection>
      </Box>
      <AccountDetailsControlGroup />
    </Row>
  );
}
