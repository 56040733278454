import { useIntl } from 'react-intl';

import { Button, Description, Row, Stack, Text } from '@ssnc/ui-core';

import { useAccountAdminStatus } from './hooks';
import { StatusFieldPlaceholder } from './components';
import { type AccountAdminStatusFieldProps } from './types';

export function AccountAdminStatusField({ disabled, ...rest }: AccountAdminStatusFieldProps) {
  const { formatMessage } = useIntl();

  const { handleStatusAction, userStatus, buttonLabel, showStatusButton, isLoading } = useAccountAdminStatus(rest);

  const showStatus = userStatus && !isLoading;

  return (
    <Stack gap={300}>
      <Text type="h5">{formatMessage({ id: 'account.details.status.label' })}</Text>
      <Description>
        <Row gap={400} align="center">
          {showStatus ? (
            <>
              <Text>{userStatus}</Text>
              {showStatusButton && (
                <Button importance="primary" disabled={isLoading || disabled} onClick={handleStatusAction}>
                  {buttonLabel}
                </Button>
              )}
            </>
          ) : (
            <StatusFieldPlaceholder />
          )}
        </Row>
      </Description>
    </Stack>
  );
}
