import { styled, css } from 'styled-components';

import { Box, Row } from '@ssnc/ui-core';

export const SubDomainLabel = styled(Box)(
  ({ theme }) => css`
    position: relative;

    & > p {
      position: absolute;
      color: ${theme.sys.color.text.disabled};
      margin-top: 3.75rem;
      margin-left: ${theme.space[100]};
    }
  `,
);

export const StyledRow = styled(Row)(
  ({ theme }) => css`
    & > p {
      margin-top: ${theme.space[50]};
    }
  `,
);
