import { type UseMutationResult } from '@tanstack/react-query';

import { IndicatorState } from '@ssnc/ui-core';

import { type ValidateDomainNameResponse, type ValidateDomainNameVariables } from '../../api';

export function parseDomainIndicatorState({
  data,
  isIdle,
  isError,
  isPending,
}: Pick<
  UseMutationResult<ValidateDomainNameResponse, ValidateDomainNameVariables>,
  'data' | 'isError' | 'isIdle' | 'isPending'
>) {
  return function validateDomain(name: string) {
    const { subDomain, validDomainName } = data?.validateTenantDomainName || {};

    if (isPending) {
      return IndicatorState.PENDING;
    }

    if (validDomainName === false || isError) {
      return IndicatorState.WARNING;
    }

    if (subDomain !== name || isIdle) {
      return IndicatorState.IDLE;
    }

    return IndicatorState.COMPLETE;
  };
}
