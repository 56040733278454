import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { RouterTabs } from '@blueprism/nextgen-platform-components';

import { AppRoutes } from 'routes';
import { type AccountDetailsParams } from 'types';

export const AccountNavigationRouterTabs = () => {
  const { accountId: id, regionName } = useParams<AccountDetailsParams>();
  const { formatMessage } = useIntl();

  const tabsInfo = [
    {
      label: formatMessage({ id: 'account.details.btn.accountDetails' }),
      route: AppRoutes.getAccountDetailsUrl(regionName, id),
    },
    {
      label: formatMessage({ id: 'account.details.btn.products' }),
      route: `${AppRoutes.getAccountDetailsUrl(regionName, id)}/products`,
    },
    {
      label: formatMessage({ id: 'account.details.btn.onboarding' }),
      route: `${AppRoutes.getAccountDetailsUrl(regionName, id)}/onboarding`,
    },
  ];

  return (
    <RouterTabs
      ariaLabel={formatMessage({ id: 'account.details.router.tabs' })}
      name="account-page-tabs"
      tabsInfo={tabsInfo}
    />
  );
};
