import { type PropsWithChildren, createContext, useState, useLayoutEffect } from 'react';

import { generatePublicProtocolHostUrl } from 'utils';

import { type ConfigState, type ConfigStateContext } from './types';

export const ConfigContext = createContext<ConfigStateContext | undefined>(undefined);

export function ConfigProvider({ children }: PropsWithChildren) {
  const [configReady, setConfigReady] = useState(false);

  const configUrlLocation =
    process.env.PUBLIC_URL !== '/' ? `${process.env.PUBLIC_URL.substring(1)}/config.json` : 'config.json';

  const configName = process.env.NODE_ENV === 'production' ? configUrlLocation : 'config.development.json';

  const [configState, setConfigState] = useState<ConfigState>({
    ADMIN_API_GATEWAY: '',
  });

  async function getConfig() {
    setConfigReady(false);

    const configEndpoint = generatePublicProtocolHostUrl(configName);

    const configHeaders: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      },
    };

    const response = await fetch(configEndpoint, configHeaders);

    const data: ConfigState = await response.json();

    setConfigState(data);
    setConfigReady(true);
  }

  useLayoutEffect(() => {
    getConfig();
  }, []);

  return (
    <ConfigContext.Provider value={{ configState, setConfigState, configReady }}>{children}</ConfigContext.Provider>
  );
}
