import * as Yup from 'yup';
import { useIntl } from 'react-intl';

import { PageLayout } from '@ssnc/ui-layouts';
import { Text, Breadcrumbs, Link } from '@ssnc/ui-core';
import { Form } from '@ssnc/ui-pattern-forms';

import { ApiErrorBoundary } from '@blueprism/nextgen-api';
import { ComboBoxField, type ComboBoxFieldOption } from '@blueprism/nextgen-platform-components';
import { useBreadcrumbs } from '@blueprism/nextgen-shared-hooks';
import { isEmpty } from '@blueprism/nextgen-utils';

import { AppRoutes } from 'routes';
import { useRegionContext } from 'services/region';

import { TenantListView } from './TenantListView';

export function TenantListPage() {
  const { formatMessage } = useIntl();

  const breadCrumbLinks = [{ href: AppRoutes.Account, title: formatMessage({ id: 'navigation.account.label' }) }];
  const { breadcrumbs } = useBreadcrumbs(breadCrumbLinks);

  const { managementRegionsOptions, managementRegionsLoading, selectedManagementRegion, selectManagementRegion } =
    useRegionContext();

  const managementRegionSchema = Yup.object().test({
    test: (value: Yup.AnyObject) => value?.id,
    message: formatMessage({ id: 'account.form.managementRegion.error.required.message' }),
  });

  function handleManagementRegionChange(managementRegion: ComboBoxFieldOption | undefined) {
    if (isEmpty(managementRegion)) return;

    selectManagementRegion(managementRegion);
  }

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs ariaLabel={formatMessage({ id: 'navigation.breadcrumbs.tenantList.ariaLabel' })}>
          {breadcrumbs.map(({ title, navigable, ...linkProps }) =>
            navigable ? (
              <Link {...linkProps} key={title}>
                {title}
              </Link>
            ) : (
              <Text key={title}>{title}</Text>
            ),
          )}
        </Breadcrumbs>
      }
      pageTitle={<Text type="h2">{formatMessage({ id: 'header.text.accounts' })}</Text>}
      universalSwitcher={
        <Form initialValues={{ managementRegion: selectedManagementRegion }}>
          <ComboBoxField
            name="managementRegion"
            label={formatMessage({ id: 'account.form.managementRegion.label' })}
            onChange={handleManagementRegionChange}
            initialValue={selectedManagementRegion?.value}
            options={managementRegionsOptions}
            loading={managementRegionsLoading}
            validate={managementRegionSchema}
          />
        </Form>
      }
      pageContent={
        <ApiErrorBoundary>
          <TenantListView />
        </ApiErrorBoundary>
      }
    />
  );
}
