import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { ToastType, useToast } from '@ssnc/ui-pattern-toasts';

import { useApiClient } from '@blueprism/nextgen-api';

import { QueryKeys } from 'types/queries';
import { type AccountDetailsParams } from 'types';
import { getManagementRegionPrefix } from 'services/region';

import {
  SEND_TENANT_ADMIN_INVITATION_MUTATION,
  type SendInvitationResponse,
  type SendInvitationVariables,
} from './api';

export function useSendInvitationApi(tenantId: string) {
  const { formatMessage } = useIntl();
  const { triggerToast } = useToast();
  const queryClient = useQueryClient();
  const { requestWithPrefix } = useApiClient();
  const { regionName } = useParams<AccountDetailsParams>();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (variables: SendInvitationVariables) =>
      requestWithPrefix<SendInvitationResponse>(
        getManagementRegionPrefix(regionName),
        SEND_TENANT_ADMIN_INVITATION_MUTATION,
        variables,
      ),
  });

  async function handleSendInvitation() {
    await mutateAsync({ tenantId: tenantId });

    triggerToast({
      type: ToastType.SUCCESS,
      title: formatMessage({ id: 'account.invitation.notification.success.title' }),
      description: formatMessage({ id: 'account.invitation.notification.success.description' }),
    });

    await queryClient.invalidateQueries({ queryKey: [QueryKeys.GetAccountAdminStatus, tenantId, regionName] });
  }

  return {
    handleSendInvitation,
    isLoading: isPending,
  };
}
