import * as Yup from 'yup';

import { yupSequence } from '@ssnc/ui-pattern-forms';

import { type CreateValidation } from '@blueprism/nextgen-platform-components';

import { type LanguageKeys } from 'translations';

import { ACCOUNT_NAME_MAX_LENGTH } from './constants';
import { type UpdateTenantInput } from './types';

const disallowedCharacters = /^[A-Za-z0-9_-\s]+$/;

export const createValidation: CreateValidation<UpdateTenantInput> = (formatMessage) => {
  const t = (text: LanguageKeys) => formatMessage({ id: text });

  return {
    nameSchema: yupSequence('name', Yup.string(), (type) => [
      type.required(t('account.create.companyName.error.required.message')),
      type.trim(t('account.create.companyName.error.required.message')),
      type.max(ACCOUNT_NAME_MAX_LENGTH, t('account.create.companyName.error.max.length')),
      type.matches(disallowedCharacters, {
        name: 'disallowed-characters-validation',
        message: ({ value }: { value: string }) => {
          if (!value) return undefined;

          return t('account.create.companyName.error.invalid.characters');
        },
      }),
      type.test({
        test: (value: string | undefined) => !value?.startsWith('_'),
        name: 'restrict-start-with-hyphen-validation',
        message: t('account.create.companyName.error.starts.with.underscore'),
      }),
      type.test({
        test: (value: string | undefined) => !value?.startsWith('-'),
        name: 'restrict-start-with-underscore-validation',
        message: t('account.create.companyName.error.starts.with.hyphen'),
      }),
      type.test({
        test: (value: string | undefined) => !value?.startsWith(' '),
        name: 'restrict-start-with-space-validation',
        message: t('account.create.companyName.error.starts.with.space'),
      }),
    ]),
  };
};
