import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useField } from 'formik';

import { Box, Button, IndicatorState, Row, StatusIndicator, Text } from '@ssnc/ui-core';
import { TextFormField } from '@ssnc/ui-pattern-forms';

import { createValidation } from '../../validationSchema';
import { useTenantDomainApi } from '../../hooks';
import { generateDomainName, getDomainNameMaxLength } from '../../utils';
import { type ValidateDomainNameResponse } from '../../hooks/useTenantDomainApi/api';

import { StyledRow, SubDomainLabel } from './CompanyAndDomainFields.styled';
import { type CompanyAndDomainFieldsProps } from './types';

export function CompanyAndDomainFields({
  domain,
  maxTenantSubdomainLevel,
  setIsDomainVerified,
  isDomainNotVerified,
  setIsDomainNotVerified,
}: CompanyAndDomainFieldsProps) {
  const { formatMessage } = useIntl();
  const [{ value: domainName }, { error }, { setValue, setError }] = useField('domainName');
  const [{ value: managementRegion }] = useField('managementRegion');
  const managementLocation = managementRegion?.id;

  const { getDomainIndicatorStatus, validatingDomain, validateDomain } = useTenantDomainApi(managementLocation);

  const domainStatus = getDomainIndicatorStatus(domainName);
  const isSubmitDisabled = domainStatus !== IndicatorState.COMPLETE || validatingDomain;

  const charactersLeft = getDomainNameMaxLength(maxTenantSubdomainLevel) - (domainName?.length || 0);

  useEffect(() => {
    const isStatusCompleted = domainStatus === IndicatorState.COMPLETE;
    setIsDomainVerified(isStatusCompleted);

    if (domainStatus !== IndicatorState.IDLE) {
      setIsDomainNotVerified(false);
    }
  }, [domainStatus]);

  function handleValidationResponse(data: ValidateDomainNameResponse | undefined) {
    const { subDomain, validDomainName } = data?.validateTenantDomainName || {};

    if (!subDomain || domainName === subDomain || !validDomainName) {
      return;
    }

    setValue(subDomain);
    setError(undefined);
  }

  async function handleValidateDomainClick() {
    if (!domainName) return;

    const response = await validateDomain({
      domainName,
    });

    handleValidationResponse(response);
  }

  useEffect(() => {
    if (managementRegion?.id) {
      handleValidateDomainClick();
    }
  }, [managementRegion?.id]);

  const { domainNameSchema, nameSchema } = createValidation(formatMessage, maxTenantSubdomainLevel);

  async function handleTenantNameBlur(event: React.FocusEvent) {
    if (domainName) {
      return;
    }

    const target = event.target as HTMLInputElement;

    const suggestedName = generateDomainName(target.value);

    if (!suggestedName) {
      return;
    }

    const response = await validateDomain({
      domainName: suggestedName,
    });

    handleValidationResponse(response);
  }

  return (
    <>
      <TextFormField
        name="name"
        label={formatMessage({ id: 'account.create.tenantName.label' })}
        required
        requiredLabel={formatMessage({ id: 'account.form.requiredLabel' })}
        validate={nameSchema}
        onBlur={handleTenantNameBlur}
      />

      <Box>
        <Row>
          <Box width="22rem">
            <TextFormField
              name="domainName"
              label={formatMessage({ id: 'account.create.domainName.label' })}
              helperText={formatMessage({ id: 'account.create.domainName.helperText' }, { domain })}
              required
              requiredLabel={formatMessage({ id: 'account.form.requiredLabel' })}
              validate={domainNameSchema}
              disabled={validatingDomain}
            />
            <StyledRow justify="end">
              <Text type="caption">
                {formatMessage({ id: 'account.create.domainName.charactersLeft' }, { charactersLeft })}
              </Text>
            </StyledRow>
          </Box>
          <SubDomainLabel>
            <Text type="body">{domain}</Text>
          </SubDomainLabel>
        </Row>
        <Row align="center" padding={150} gap={150}>
          <Button onClick={handleValidateDomainClick} disabled={Boolean(!isSubmitDisabled || !domainName || error)}>
            {formatMessage({ id: 'account.create.domainName.button.label' })}
          </Button>
          <Box>
            <StatusIndicator
              status={isDomainNotVerified ? IndicatorState.WARNING : domainStatus}
              statusLabels={[
                formatMessage({ id: 'account.create.domainName.state.pending.label' }),
                formatMessage({ id: 'account.create.domainName.state.complete.label' }),
                isDomainNotVerified
                  ? formatMessage({ id: 'account.create.domainName.state.warning.notVerified.label' })
                  : formatMessage({ id: 'account.create.domainName.state.warning.label' }),
              ]}
            />
          </Box>
        </Row>
      </Box>
    </>
  );
}
