import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { NoContent, NoContentInformation } from '@ssnc/ui-layouts';
import { Details, Refresh } from '@ssnc/ui-icons';

import { useAccountMultiRegionRouting } from 'hooks';
import { useProductActivationDrawerContext } from 'components';
import { type AccountDetailsParams } from 'types';

import { ProductCard, ProductGroup } from './components';
import { useProductsApi } from './hooks';
import { StyledStack } from './StyledStack.styled';

export function Products() {
  const { formatMessage } = useIntl();
  const { accountId = '', regionName = '' } = useParams<AccountDetailsParams>();
  const { goToProductSkusListPage } = useAccountMultiRegionRouting(regionName);

  const { openDrawer } = useProductActivationDrawerContext();

  const { isLoading, activatedProducts, notActivatedProducts } = useProductsApi(accountId);

  const isActiveProductsExists = activatedProducts.length > 0;
  const isNotActiveProductsExists = notActivatedProducts.length > 0;

  const activeProductCards = activatedProducts.map(({ name, id }) => {
    function handleOnClick() {
      goToProductSkusListPage(accountId, id);
    }

    return (
      <ProductCard
        onClick={handleOnClick}
        label={name}
        buttonLabel="account.products.availableProducts.configure.button.label"
        key={name}
      />
    );
  });

  const notActiveProductCards = notActivatedProducts.map(({ name = '', id }) => {
    function handleOnClick() {
      const drawerHeader = formatMessage(
        {
          id: 'account.products.activateProducts.drawer.header',
        },
        { productSetName: name },
      );

      openDrawer({
        header: drawerHeader,
        name,
        id,
      });
    }

    return (
      <ProductCard
        key={name}
        onClick={handleOnClick}
        label={name}
        buttonLabel="account.products.availableProducts.activate.button.label"
      />
    );
  });

  return (
    <StyledStack gap={300}>
      {isLoading ? (
        <NoContentInformation
          icon={<Refresh />}
          title={formatMessage({ id: 'account.products.loading.header' })}
          description={formatMessage({ id: 'account.products.loading.description' })}
        />
      ) : (
        <>
          {isActiveProductsExists && (
            <ProductGroup title={formatMessage({ id: 'account.products.activeProducts.header' })}>
              {activeProductCards}
            </ProductGroup>
          )}

          {isNotActiveProductsExists && (
            <ProductGroup title={formatMessage({ id: 'account.products.availableProducts.header' })}>
              {notActiveProductCards}
            </ProductGroup>
          )}

          {!isActiveProductsExists && !isNotActiveProductsExists && (
            <NoContent icon={<Details />} title={formatMessage({ id: 'account.products.noContent.title' })} />
          )}
        </>
      )}
    </StyledStack>
  );
}
