import { type PropsWithChildren } from 'react';

import { ProductActivationForm } from './components';
import { ProductActivationDrawerProvider } from './context';

export function ProductActivationFormDrawer({ children }: PropsWithChildren) {
  return (
    <ProductActivationDrawerProvider>
      {children}
      <ProductActivationForm />
    </ProductActivationDrawerProvider>
  );
}
