import { type PropsWithChildren, createContext, useState } from 'react';

import { DiscardChangesModalProvider } from '@blueprism/nextgen-platform-components';

import { CurrentSkuProvider } from '../CurrentSkuProvider';

import { type ProductActivationDrawerContextState, type ProductDrawerProps } from './types';

export const ProductActivationDrawerContext = createContext<ProductActivationDrawerContextState>({
  drawerState: null,
  openDrawer: () => {},
  closeDrawer: () => {},
});

export function ProductActivationDrawerProvider({ children }: PropsWithChildren) {
  const [drawerState, setDrawerState] = useState<ProductDrawerProps | null>(null);

  function openDrawer(drawer: ProductDrawerProps | null) {
    setDrawerState(drawer);
  }

  function closeDrawer() {
    setDrawerState(null);
  }

  return (
    <ProductActivationDrawerContext.Provider value={{ drawerState, openDrawer, closeDrawer }}>
      <DiscardChangesModalProvider>
        <CurrentSkuProvider>{children}</CurrentSkuProvider>
      </DiscardChangesModalProvider>
    </ProductActivationDrawerContext.Provider>
  );
}
