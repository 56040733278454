import { Row, Placeholder } from '@ssnc/ui-core';

export function StatusFieldPlaceholder() {
  return (
    <Row gap={200}>
      <Placeholder height={500} width="10rem" />
      <Placeholder height={500} width="10rem" />
    </Row>
  );
}
