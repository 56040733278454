import { StatusLabel } from '@ssnc/ui-core';

import { type FormatMessage } from '@blueprism/nextgen-utils';

import { SkuStatus } from 'types/entities/Sku';
import { getLicenseName } from 'components';

import { type ProductSku } from './hooks/useActivatedSkusByProduct/api';

export function formatSkuStatus(status: `${SkuStatus}` | undefined, formatMessage: FormatMessage) {
  const message = status ? formatMessage({ id: `account.productConfiguration.table.status.${status}` }) : '';

  if (status === SkuStatus.ACTIVE) {
    return <StatusLabel variant="success">{message}</StatusLabel>;
  }

  return <StatusLabel variant="warning">{message}</StatusLabel>;
}

export function getTranslatedProductLicensesList(listData: ProductSku[] = [], formatMessage: FormatMessage) {
  const licensesList = listData.reduce((acc, { licenses }) => {
    const licenseNames: string[] = [];
    licenses?.forEach(({ displayName }) => {
      // TODO Will be removed in @PLAT-11075
      if (displayName === 'PROCESSREPOSITORY') return;

      licenseNames.push(getLicenseName(displayName, formatMessage));
    });

    return [...acc, ...licenseNames];
  }, [] as string[]);

  return [...new Set(licensesList)];
}
