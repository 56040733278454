import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';
import { isEmpty } from '@blueprism/nextgen-utils';

import { QueryKeys } from 'types/queries';
import { type ThrowOnError } from 'types';

import { GET_MANAGEMENT_REGIONS, type GetManagementRegionsResponse } from './api';

export function useManagementRegionsApi(throwOnError: ThrowOnError) {
  const { requestWithPrefix } = useApiClient();

  // TODO: Now this query is hardcoded with region value 'regions/us-east',
  // but in the future this should be query without the specified region prefix.
  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GetManagementRegionList],
    queryFn: () => requestWithPrefix<GetManagementRegionsResponse>('regions/us-east', GET_MANAGEMENT_REGIONS),
    throwOnError,
  });

  const managementRegionsOptions = useMemo(() => {
    const { managementRegions } = data || {};

    if (isEmpty(managementRegions)) return [];

    return managementRegions.map(({ name, displayName }) => ({
      id: name,
      value: displayName,
    }));
  }, [data]);

  return { managementRegionsOptions, isLoading };
}
