import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Button, Row, Stack, Text, useTheme } from '@ssnc/ui-core';
import { Cross, Tick } from '@ssnc/ui-icons';

import { ScrollToTop } from '@blueprism/nextgen-platform-components';
import { Table, type TableColumns, useTable } from '@blueprism/nextgen-platform-table';

import { AppRoutes } from 'routes';
import { type AccountDetailsParams } from 'types';

import { useTenantDetailsApi } from '../hooks';

import { useAccountAdminStatusApi } from './hooks';
import { AccountAdminStatusField } from './components';
import { type OnboardingStatus } from './types';

export function Onboarding() {
  const { accountId: id = '', regionName } = useParams<AccountDetailsParams>();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();

  const { onboarding, isLoading } = useTenantDetailsApi(id);
  const { tenantAdminDetails } = useAccountAdminStatusApi(id);

  function handleRedirectToProducts() {
    navigate(AppRoutes.getProductsURL(regionName, id));
  }

  const disabled = Object.entries(onboarding).some(([key, value]) => key !== 'isTenantActivated' && !value);

  const listData = Object.entries(onboarding).map<OnboardingStatus>(
    ([key, value]) =>
      ({
        id: key,
        status: { [key]: value },
      }) as OnboardingStatus,
  );

  const tableColumns: TableColumns<OnboardingStatus> = {
    status: {
      order: 1,
      header: formatMessage({ id: 'account.table.status' }),
      tableCell: (status = {}) => {
        const [[key, value]] = Object.entries(status);

        const isProductConfigured = key === 'hasProductConfigured';

        return (
          <Row aria-disabled justify="between" align="center">
            <Row gap={150}>
              {value ? (
                <Tick size={24} color={theme.sys.color.icon.selected} />
              ) : (
                <Cross size={24} color={theme.sys.color.icon.disabled} />
              )}
              <Text themeColor={value ? 'text' : 'disabled'}>{formatMessage({ id: `account.onboarding.${key}` })}</Text>
            </Row>

            {isProductConfigured && (
              <Button onClick={handleRedirectToProducts}>
                {formatMessage({ id: 'account.onboarding.configureProducts.button' })}
              </Button>
            )}
          </Row>
        );
      },
      width: '20%',
    },
  };

  const { ...tableProps } = useTable({
    tableConfig: {
      columns: tableColumns,
    },
  });

  return (
    <Stack gap={400} width="100%" justify="between">
      <ScrollToTop />
      <Stack gap={300} width="40rem">
        <Text type="h5">{formatMessage({ id: 'account.onboarding.title.signUpProgress' })}</Text>
        <Table rowId="id" isLoading={isLoading} data={listData} {...tableProps} />
      </Stack>
      <AccountAdminStatusField {...tenantAdminDetails} disabled={disabled} />
    </Stack>
  );
}
