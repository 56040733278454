import { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import { Stack } from '@ssnc/ui-core';
import { DatePicker } from '@ssnc/ui-pattern-date-picker';
import { FormField } from '@ssnc/ui-pattern-forms';

import { initialValues } from 'components/ProductActivationFormDrawer/constants';
import { type License } from 'types/entities';

import { createValidation } from './validationSchema';
import { type DatePickerValue } from './types';

export function FromToPicker() {
  const { values, setFieldTouched, setFieldValue, validateField, isSubmitting } = useFormikContext<License>();

  useEffect(() => {
    if (!values.startDate && !values.endDate) return;

    setFieldTouched('startDate', true);
    validateField('startDate');
    setFieldTouched('endDate', true);
    validateField('endDate');
  }, [values.startDate, values.endDate]);

  const handleValueChange = (name: string, value?: Date) => {
    setFieldValue(name, value);
    setFieldTouched(name, true);
    validateField(name);
  };

  const { formatMessage } = useIntl();

  const { startDateSchema, endDateSchema } = createValidation(formatMessage);

  return (
    <Stack gap={300}>
      <FormField
        required
        validate={startDateSchema}
        initialValue={initialValues.startDate}
        name="startDate"
        id="from-picker-licenses"
        label={formatMessage({ id: 'date.picker.from.label' })}
        helperText={formatMessage({ id: 'account.products.activateProducts.drawer.form.fromDatePicker.helperText' })}
        disabled={isSubmitting}
      >
        {(props) => {
          return (
            <DatePicker
              {...props}
              defaultValue={props.value as DatePickerValue}
              prevButtonAriaLabel={formatMessage({
                id: 'account.products.activateProducts.drawer.form.fromDatePicker.previous.button.ariaLabel',
              })}
              nextButtonAriaLabel={formatMessage({
                id: 'account.products.activateProducts.drawer.form.fromDatePicker.next.button.ariaLabel',
              })}
              toggleButtonAriaLabel={formatMessage({
                id: 'account.products.activateProducts.drawer.form.fromDatePicker.toggle.button.ariaLabel',
              })}
              onChange={({ date }) => handleValueChange('startDate', date)}
            />
          );
        }}
      </FormField>
      <FormField
        required
        initialValue={initialValues.endDate}
        name="endDate"
        id="to-picker-licenses"
        validate={endDateSchema}
        label={formatMessage({ id: 'date.picker.to.label' })}
        helperText={formatMessage({ id: 'account.products.activateProducts.drawer.form.fromDatePicker.helperText' })}
        disabled={isSubmitting}
      >
        {(props) => (
          <DatePicker
            {...props}
            minDate={new Date()}
            defaultValue={props.value as DatePickerValue}
            prevButtonAriaLabel={formatMessage({
              id: 'account.products.activateProducts.drawer.form.toDatePicker.previous.button.ariaLabel',
            })}
            nextButtonAriaLabel={formatMessage({
              id: 'account.products.activateProducts.drawer.form.toDatePicker.next.button.ariaLabel',
            })}
            toggleButtonAriaLabel={formatMessage({
              id: 'account.products.activateProducts.drawer.form.toDatePicker.toggle.button.ariaLabel',
            })}
            onChange={({ date }) => handleValueChange('endDate', date)}
          />
        )}
      </FormField>
    </Stack>
  );
}
