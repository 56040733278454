import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

import { Box } from '@ssnc/ui-core';
import { Pencil } from '@ssnc/ui-icons';
import { ToolbarButton } from '@ssnc/ui-pattern-toolbar';

import { useAccountMultiRegionRouting } from 'hooks';
import { type AccountDetailsParams } from 'types';

export function AccountDetailsControlGroup() {
  const { formatMessage } = useIntl();
  const { accountId: id, regionName } = useParams<AccountDetailsParams>();
  const { pathname } = useLocation();

  const { goToEditAccountDetails } = useAccountMultiRegionRouting(regionName);

  function handleEditButtonClick() {
    goToEditAccountDetails(id);
  }

  const showEditButton = !pathname.endsWith('/edit');

  return showEditButton ? (
    <Box>
      <ToolbarButton onClick={handleEditButtonClick} icon={<Pencil size={16} />} disabled={!id}>
        {formatMessage({ id: 'buttons.edit' })}
      </ToolbarButton>
    </Box>
  ) : null;
}
