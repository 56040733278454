import { styled } from 'styled-components';

import { Stack } from '@ssnc/ui-core';

export const StyledListItem = styled.li`
  list-style-type: disc;
  list-style-position: inside;
`;

export const StyledStack = styled(Stack)`
  margin-top: ${({ theme }) => theme.space[300]};
`;
