import { SortDirection } from '@ssnc/ui-pattern-table';

import { type SortingOptions } from '@blueprism/nextgen-platform-table';

import { type QueryOptions } from 'pages/TenantList/types';

export const INITIAL_SORTING_OPTIONS: SortingOptions = {
  accessor: 'name',
  order: SortDirection.ASCENDING,
};

export const DEFAULT_QUERY_OPTIONS: QueryOptions = {
  pageSize: 10,
  sortingOptions: INITIAL_SORTING_OPTIONS,
  currentPage: 1,
  nextPage: '',
};
